import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
// right-h
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png"
// for animation
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter'


const Hero = () => {
  const transition = {type:'spring',duration:4}
  const mobile = window.innerWidth<=768 ? true:false
  return (
    <div className="hero" id="home">
        <div className="blur hero-blur"></div>

      <div className="left-h">
        <Header />
        {/* the best ad */}
        <div className="the-best-ad">
          {/* motion animation */}
          <motion.div
          initial={{left:mobile?"178px":"238px"}}
          whileInView={{left:'8px'}}
          transition={{...transition,type:'tween'}}



          ></motion.div>
          <span>the best fitness club in the town</span>
        </div>
        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
           <span>Your</span>
          </div>

          <div>
            <span>Ideal body</span>
          </div>

          <div><span>In here we will help you to shape and build your ideal body and live up your life to fullest</span></div>
        </div>

        {/* figures */}
        <div className="figures">
          <div>
            <span><NumberCounter end={110} start={30} delay='4' preFix="+"></NumberCounter></span>
            <span>expert coachs</span>
          </div>

          <div>
            <span><NumberCounter start={600} end={989} delay="4" preFix="+"></NumberCounter></span>
            <span>members joined</span>
          </div>

          <div>
            <span><NumberCounter start={1} end={55} preFix="+" delay="4"></NumberCounter></span>
            <span>fitness programs</span>
          </div>

        
        </div>

        {/* Hero buttons */}
        <div className="hero-buttons">
          <button  className="btn">
            Get Started

            </button>
            
            <button className="btn">
              Learn More

            </button>

        </div>


      </div>
      {/* right side Hero */}
      <div className="right-h">
        
        <button className="btn btn-r-h">Join Now</button>

        <motion.div
        transition={transition}
        initial={{top:"-4rem"}}
        whileInView={{top:"6rem"}}
         className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>111 bpm</span>
        </motion.div>

        {/* hero images */}
        <img src={hero_image} alt="" className="hero_image" />
        <motion.img 
        transition={transition}
        initial={{right:"11rem"}}
        whileInView={{right:"20rem"}} src={hero_image_back} alt="" className="hero_image_back"/>
        {/* calories */}
        <motion.div
        transition={transition}
        initial={{right:"37rem"}}
        whileInView={{right:"28rem"}} className="calories">
          <img src={Calories} alt="" />

        <div>
          <span>Calories Burned </span>
          <span>220 kcal</span>
        </div>

        </motion.div>
        

      </div>
    </div>
  );
};

export default Hero;
