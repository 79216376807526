import React from 'react'
import './Programs.css';
import rightArrow from "../../assets/rightArrow.png"

import { programsData } from '../../data/programsData';

const Programs = () => {
  return (
    <div className='programs' id='programs'>
        {/* header */}
        <div className="programs-header">
            <span className='stroke-text'>Explore our</span>
            <span>Programs</span>
            <span className='stroke-text'>To Shape You :)</span>
        </div>

        <div className="programs-categories">
        {programsData.map((program, index) => (
          <div className="category" key={index}>
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-now">
                <span>Join Now</span>
                <img src={rightArrow} alt="" />
            </div>
          </div>
        ))}
      </div>
      
    </div>
  )
}

export default Programs
