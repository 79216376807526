import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

const Join = () => {
    const form = useRef()

    // copy code from rect emailjs pack example
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_8bxu8a7', 'template_m3m29nx', form.current, {
            publicKey: 'Dqa2sP6cu_3pWgnZl',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };

  return (
    <div className='Join'>
        
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>READY TO</span>
                <span>LEVEL UP</span>
            </div>

            <div>
                <span>YOUR BODY</span>
                <span className='stroke-text'>WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} action="" className='email-container'>
                <input type="email" name='user_email' placeholder='Enter Your Email Address'/>
                <button className='btn btn-j'>Join Now</button>
            </form>
        </div>
      
    </div>
  )
}

export default Join
